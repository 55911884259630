@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.42);
  width: calc(100vw - (100vw - 100%));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  @apply w-full;
}

h1 {
  @apply text-4xl my-4;
}

h2 {
  @apply text-3xl my-4;
}

h3 {
  @apply text-2xl my-3;
}

h4 {
  @apply text-xl my-3;
}

h5 {
  @apply text-lg my-2;
}

h6 {
  @apply text-base my-2 font-medium;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  @apply mt-0;
}

p, ul, ol {
  @apply mb-2;
}

strong, a {
  @apply font-bold;
}

button {
  -webkit-tap-highlight-color: transparent;
}

button[disabled] {
  @apply opacity-50;
}

ul li {
  @apply list-disc list-inside
}